

export default {
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications
  },
  ADD_NOTIFICATION(state, notification) {
      state.notifications.unshift(notification)
  },
  MARK_AS_READ(state, notifId){
  	state.notifications.map((item)=>{
  		if(item.id == notifId){
  			return item.is_seen = 1
  		}
  	})
  }

}
