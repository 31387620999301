import Vue from 'vue'

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  let arr = value.split(" ")
  let capitalized_array = []
  arr.forEach((word) => {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(" ");
})

Vue.filter('title', function (value, replacer="_") {
  if (!value) return ''
  value = value.toString()

  let arr = value.split(replacer)
  let capitalized_array = []
  arr.forEach((word) => {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(" ");
})

Vue.filter('truncate', function(value, limit) {
  if(value.length > limit){
    return value.substring(0, limit)+'...'
  }else{
    return value
  }
})

Vue.filter('delivery_time', function(time) {
  if(!time){return}
  return (time > 24) ? Math.round(time/24) + ' days' : '1 day'
})


Vue.filter('truncate_start', function(value, limit) {
  if(value.length > limit){
    return '...'+value.substring(value.length, value.length - limit)
  }else{
    return value
  }
})

Vue.filter('tailing', function(value, tail) {
  return value + tail;
})

Vue.filter('time', function(value, is24HrFormat = false) {
  if(value) {
    const date = new Date(Date.parse(value));
    let hours = date.getHours();
    const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
    if(!is24HrFormat) {
      const time = hours > 12 ? 'AM' : 'PM';
      hours = hours % 12 || 12;
      return hours + ':' + min + ' ' + time
    }
    return hours + ':' + min
  }
})

Vue.filter('date_parse', function(value, fullDate = false) {
  var options1 = { month: 'numeric', day: 'numeric' };
  var options2 = {year: 'numeric', month: 'numeric', day: 'numeric' };
  var givenDate  = new Date(value);
  if(!value || givenDate == 'Invalid Date'){return;}
  if(!fullDate) return givenDate.toLocaleDateString("en-US", options1);
  else return givenDate.toLocaleString("en-US",  options2);

})

Vue.filter('date', function(value, fullDate = false) {
  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  var givenDate  = new Date(value);
  if(!value || givenDate == 'Invalid Date'){return;}
  if(!fullDate) return givenDate.toLocaleDateString("en-US", options);
  else return givenDate.toLocaleString("en-US");
})

Vue.filter('date_time', function(value, time = false) {
  var givenDate  = new Date(value).toLocaleString("en-US");
  if(time) return givenDate.split(',')[1];
  else return givenDate.split(',')[0];
})

Vue.filter('month', function(val, showYear = true) {
  val = String(val)

  const regx = /\w+\s(\w+)\s\d+\s(\d+)./;
  if(!showYear) {
    return regx.exec(val)[1];
  }else {
    return regx.exec(val)[1] + ' ' + regx.exec(val)[2];
  }

})

Vue.filter('csv', function(value) {
  return value.join(', ')
})

Vue.filter('filter_tags', function(value) {
  return value.replace(/<\/?[^>]+(>|$)/g, "")
})

Vue.filter('k_formatter', function(num) {
  return num > 999 ? (num/1000).toFixed(1) + 'k' : num
})

Vue.filter('money_format', function(number, decimal=true) {
  let i = parseInt(number); if(isNaN(i)) {return;}
  return i.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
})


Vue.filter('date_ago', function(utctime) {
  let x        = new Date(utctime)
  if(!utctime || x == 'Invalid Date'){return;}
  var o = {
    sec: 1000,
    min: 60 * 1000,
    hr: 60 * 1000 * 60,
    day: 24 * 60 * 1000 * 60,
    week: 7 * 24 * 60 * 1000 * 60,
    month: 30 * 24 * 60 * 1000 * 60,
    year: 365 * 24 * 60 * 1000 * 60
  };
  var ago = function(nd, s) {
    var r = Math.round,
        dir = ' ago',
        pl = function(v, n) {
          return (s === undefined) ? n + ' ' + v + (n > 1 ? 's' : '') + dir : n + v.substring(0, 1)
        },
        ts = Date.now() - new Date(nd).getTime(),
        ii;
    if( ts < 0 ){
      ts *= -1;
      dir = ' from now';
    }
    for (var i in o) {
      if (r(ts) < o[i]) return pl(ii || 'm', r(ts / (o[ii] || 1)))
      ii = i;
    }
    return pl(i, r(ts / o[i]));
  }
  return ago(x);
})

