
import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

import moduleNotification from "./notification/moduleNotification.js"
import moduleOrderList from './order-list/moduleOrderList.js'


export default new Vuex.Store({
    state,
    actions,
    getters,
    mutations,
    modules: {
      notification: moduleNotification,
      orderList: moduleOrderList
    },
    strict: process.env.NODE_ENV !== 'production'
})
