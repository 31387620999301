// AutoLogin/Out Calls
import "@/auth/authService"

import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)

Vue.use(Vuesax)

// axios
import axios from "./axios.js"
Vue.prototype.$http = axios

// Theme Configurations
import './themeConfig.js'
// Globally Registered Components
import './globalComponents.js'

// Styles: SCSS
import './assets/scss/main.scss'
// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'

// Vuex Store
import store from './store/store'

// i18n
import i18n from './i18n/i18n'

// Pusher
import Pusher from 'pusher-js'

import Echo from "laravel-echo"

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '5fd353761fa4166432d6',
    cluster: 'eu',
    forceTLS: true,
    // namespace: 'App.Event',
});

// Filters
import './filters/filters'

// Mixins
import mixins from './mixins/mixins'
Vue.mixin(mixins);

// VeeValidate
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate);

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// Feather font icon
require('@assets/css/iconfont.css')

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
