
import axios from "@/axios.js"

export default {
  fetchNotifications({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/notifications")
        .then((response) => {
          if(response.data.success){
              commit('SET_NOTIFICATIONS', response.data.notifiactions)
            }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  markNotifAsRead({ commit }, notifId) {
    return new Promise((resolve, reject) => {
      axios.get(`/notifications/${notifId}/markasread`)
        .then((response) => {
          if(response.data.success){
            commit('MARK_AS_READ', notifId)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}
