
import state from './moduleOrderListState.js'
import mutations from './moduleOrderListMutations.js'
import actions from './moduleOrderListActions.js'
import getters from './moduleOrderListGetters.js'

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

