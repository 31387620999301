// en, de, fr, pt
export default {
	en: {
		Dashboard: 'Dashboard',
		Main: 'Main',
		Orders: 'Orders',
		Pending: 'Pending',
		Delivered: 'Delivered',
		Completed: 'Completed',
        Refunded: 'Refunded',
		Products: 'Products',
		AddNew: 'Add New',
		ViewList: 'View List',
		Categories: 'Categories',
		Others: 'Others',
		Users: 'Users',
		Chat: 'Chat',
		Settings: 'Settings',
		AboutUs: 'About Us',
		Contact: 'Contact',
		PrivacyPolicy: 'Privacy Policy',
		TermsConditions: 'Terms & Conditions',
		Career: 'Career',
		Payments: 'Payments',
		Apps:'Apps',
		Account: 'Account',
		Configure: 'Configure',
		Skills: 'Skills',
		Languages: 'Languages',
		Countries: 'Countries',
		FakeReviews: 'Fake Reviews',
		Reviews: "Reviews",
		Fake: "Fake",
		Real: "Real",
    },
	de: {
		Dashboard: 'Instrumententafel',
		Main: 'Main',
		Orders: 'Orders',
		Pending: 'Pending',
		Delivered: 'Delivered',
		Completed: 'Completed',
        Refunded: 'Refunded',
		Products: 'Products',
		AddNew: 'Add New',
		ViewList: 'Listenansicht',
		Categories: 'Categories',
		Others: 'Andere',
		Users: 'Benutzer',
		Chat: 'Plaudern',
		Settings: 'Settings',
		AboutUs: 'About Us',
		Contact: 'Contact',
		PrivacyPolicy: 'Privacy Policy',
		TermsConditions: 'Terms & Conditions',
		Career: 'Career',
		Payments: 'Payments',
		Apps:'Apps',
		Account: 'Account',
		Configure: 'Configure',
		Skills: 'Skills',
		Languages: 'Languages',
		Countries: 'Countries',
		FakeReviews: 'Fake Reviews',
		Reviews: "Reviews",
		Fake: "Fake",
		Real: "Real",
		},

	fr: {
		Dashboard: 'Tableau de bord',
		Main: 'Main',
		Orders: 'Autre',
		Pending: 'Pending',
		Delivered: 'Delivered',
		Completed: 'Completed',
        Refunded: 'Refunded',
		Products: 'Products',
		AddNew: 'Add New',
		ViewList: 'Voir la liste',
		Categories: 'Categories',
		Others: 'Others',
		Users: 'Utilisateur',
		Chat: 'Bavarder',
		Settings: 'Settings',
		AboutUs: 'About Us',
		Contact: 'Contact',
		PrivacyPolicy: 'Privacy Policy',
		TermsConditions: 'Terms & Conditions',
		Career: 'Career',
		Payments: 'Payments',
		Apps:'Apps',
		Account: 'Account',
		Configure: 'Configure',
		Skills: 'Skills',
		Languages: 'Languages',
		Countries: 'Countries',
		FakeReviews: 'Fake Reviews',
		Reviews: "Reviews",
		Fake: "Fake",
		Real: "Real",
    	},

	pt: {

		Dashboard: 'painel de controle',
		Main: 'Main',
		Orders: 'Orders',
		Pending: 'Pending',
		Delivered: 'Delivered',
		Completed: 'Completed',
        Refunded: 'Refunded',
		Products: 'Products',
		AddNew: 'Add New',
		ViewList: 'Exibição de lista',
		Categories: 'Categories',
		Others: 'Outras',
		Users: 'Do utilizador',
		Chat: 'Bate-papo',
		Settings: 'Settings',
		AboutUs: 'About Us',
		Contact: 'Contact',
		PrivacyPolicy: 'Privacy Policy',
		TermsConditions: 'Terms & Conditions',
		Career: 'Career',
		Payments: 'Payments',
		Apps:'Apps',
		Account: 'Account',
		Configure: 'Configure',
		Skills: 'Skills',
		Languages: 'Languages',
		Countries: 'Countries',
		FakeReviews: 'Fake Reviews',
		Reviews: "Reviews",
		Fake: "Fake",
		Real: "Real",
	}
}
