
import Vue from 'vue'
import Router from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
        {
        // =============================================================================
        // MAIN LAYOUT ROUTES - Main Routes
        // =============================================================================
            path: '',
            redirect: '/',
            component: () => import('@layouts/Main.vue'),
            children: [
                {
                    path: '/',
                    name: 'dashboard',
                    component: () => import('@views/Dashboard.vue'),
                    meta: {
                        authRequired: true,
                    }
                },
                {
                    path: '/account',
                    name: 'account',
                    component: () => import('@views/account/UserEdit.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Account', active: true },
                        ],
                        pageTitle: 'Profile',
                        authRequired: true,
                    },
                },
                {
                    path: '/orders',
                    name: 'orders',
                    component: () => import('@views/orders/OrderList.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Orders', active: true },
                        ],
                        pageTitle: 'Orders',
                        authRequired: true,
                    },
                },
                {
                    path: '/orders/:orderId(\\d+)',
                    name: 'order-view',
                    component: () => import('@views/orders/show/OrderShow.vue'),
                    meta: {
                      breadcrumb: [
                          { title: 'Home', url: '/' },
                          { title: 'Pending', url: '/orders?status=pending' },
                          { title: 'Delivered', url: '/orders?status=delivered' },
                          { title: 'Completed', url: '/orders?status=completed' },
                          { title: 'Order View', active: true },
                      ],
                      pageTitle: 'Orders',
                      authRequired: true,
                    },
                },
                {
                    path: '/notifications',
                    name: 'notifications',
                    component: () => import('@views/notification/Notifications.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Notification List', active: true },
                        ],
                        pageTitle: 'Notifications',
                        no_scroll: true,
                        authRequired: true,
                    }
                },
            ]
        },
        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
            path: '',
            component: () => import('@layouts/FullPage.vue'),
            children: [
                {
                    path: '/login',
                    name: 'login',
                    component: () => import('@views/pages/Login.vue'),
                    meta: {
                      guestRequired: true
                    }
                },
                {
                  path: '/register',
                  name: 'register',
                  component: () => import('@/views/pages/Register.vue'),
                  meta: {
                    guestRequired: true,
                  }
                },
                {
                    path: '/error-404',
                    name: 'error-404',
                    component: () => import('@views/pages/Error404.vue'),
                },
                {
                    path: '/error-500',
                    name: 'error-500',
                    component: () => import('@views/pages/Error500.vue'),
                },
                {
                    path: '/unauthorized',
                    name: 'unauthorized',
                    component: () => import('@views/pages/Unauthorized.vue'),
                },
                {
                    path: '/maintenance',
                    name: 'maintenance',
                    component: () => import('@views/pages/Maintenance.vue'),
                },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/error-404'
        },

    ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loader-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
    // if(progress){
    //     progress.end();
    // }
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()
  if(to.meta.authRequired) {
    if (!isLoggedIn) return next({path: '/login' });
  }
  if(to.meta.guestRequired) {
    if (isLoggedIn) return next({path: '/' });
  }
  return next();
});

export default router

